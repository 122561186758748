import p5 from "p5";

const sketch = (p) => {
  const loopDuration = 1000 * 3;
  const letters = "MØRNING.FYI";
  const mobileLetters = ["MØ", "RN", "ING.", "FYI"];

  var isPortrait;
  var font;
  var timeline;
  var glitchLetters;

  var showTiles = false;
  var fadeBg = "rgba(0,0,0,0.9)";
  const tiles = 22;

  var mX = 1;
  var mY = 1;

  const c = [
    "rgb(255,0,0)",
    "rgb(0,255,0)",
    "rgb(0,0,255)",
    "rgb(255,255,255)",
    "rgb(0,0,0)",
    "rgba(255,255,255,0.4)",
    "rgba(0,0,0,0.9)",
  ];

  const rgbChannels = ["rgb(255,0,0)", "rgb(0,255,0)", "rgb(0,0,255)"];

  p.preload = () => {
    font = p.loadFont("assets/fonts/1089Display-01.woff");
  };

  p.setup = () => {
    p.createCanvas(window.innerWidth, window.innerHeight).parent("interactive");
    isPortrait = checkLayout();

    mX = p.mouseX;
    mY = p.mouseY;
    p.noCursor();

    const bigLetters = new BigLetters(
      0, // x pos
      0, // y pos
      p.width, // width
      p.height, // height
      letters,
      loopDuration,
      font
    );

    glitchLetters = new Glitch();
    timeline = new Timeline([bigLetters, glitchLetters]);
  };

  p.draw = () => {
    p.background(0);

    timeline.update();

    let prevM = p.createVector(p.pwinMouseX, p.pwinMouseY);
    let currM = p.createVector(mX, mY);

    // draw cursor after intro
    if (timeline.clips[0].finished) {
      p.stroke(255);
      p.strokeWeight(1);
      p.line(prevM.x, prevM.y, mX, mY);
      p.noFill();
      let diff = p5.Vector.sub(currM, prevM);
      p.circle(mX, mY, (diff.x / 12) * (diff.y / 12) + 10);
    }
  };

  function Timeline(clips) {
    this.clips = clips;
    this.currentClip = 0;

    this.update = function () {
      if (!this.clips[this.currentClip].finished) {
        this.clips[this.currentClip].update();
      } else {
        this.clips[this.currentClip] = null;
      }
      if (
        this.clips[this.currentClip].finished &&
        this.currentClip + 1 < this.clips.length
      )
        this.currentClip++;
    };
  }

  function Glitch() {
    this.fontSize = 0;
    this.textRows = mobileLetters.length;

    this.pg = p.createGraphics(p.width, p.height);
    this.pg.textFont(font);
    this.pg.textSize(this.fontSize);

    this.tileSize = !isPortrait ? p.width / tiles : p.height / tiles;

    this.easing = 0.085;
    this.offset = p.width / (p.width / 6);
    this.glitch = false;
    this.glitchFrameStart = 230;
    this.glitchFrameStop = 0;
    this.rThresh = 0.82;

    this.update = function () {
      this.fontSize = isPortrait
        ? p.height / 4.5
        : p.width / (letters.length - 3);
      if (p.frameCount == this.glitchFrameStart) {
        this.glitch = true;
        this.glitchFrameStop = getRandomInt(
          this.glitchFrameStart + 10,
          this.glitchFrameStart + 60
        );
        this.glitchFrameStart = this.glitchFrameStart + getRandomInt(80, 300);
      }
      if (p.frameCount == this.glitchFrameStop) {
        this.glitch = false;
      }
      this.draw();
    };

    this.onResize = function (width, height) {
      this.pg.remove();
      this.pg = p.createGraphics(width, height);
      this.pg.textFont(font);
      this.tileSize = !isPortrait ? width / tiles : height / tiles;
    };

    this.draw = function () {
      let rand = p.noise(p.frameCount);
      let speedX = p.abs(p.winMouseX - p.pwinMouseX);
      let speedY = p.abs(p.winMouseY - p.pwinMouseY);

      let dx = p.mouseX - mX;
      let dy = p.mouseY - mY;

      let cen = {
        x: p.width / 2 + p.width / 120,
        y: p.height / 2 - p.height / 14,
      };

      mX += dx * this.easing;
      mY += dy * this.easing;

      p.angleMode(p.DEGREES);

      this.pg.background(0);
      this.pg.noStroke();
      this.pg.textSize(this.fontSize);

      // portrait
      if (isPortrait) {
        this.pg.textAlign(p.LEFT, p.CENTER);

        for (let i = 0; i < this.textRows; i++) {
          cen.y =
            this.fontSize * 2.5 + i * (this.fontSize * 0.8) - p.height / 3.6;

          this.pg.fill("rgba(255,255,255,0.6");
          this.pg.text(mobileLetters[i], cen.x - this.fontSize, cen.y);

          if (this.glitch && rand >= this.rThresh - 0.2) {
            let offset = this.offset + speedX / 2;
            let rgbColor = rgbChannels[getRandomInt(0, 2)];
            this.pg.fill(rgbColor);

            this.pg.text(
              mobileLetters[i],
              cen.x - this.fontSize + getRandomInt(-offset, offset),
              cen.y
            );
          }
        }
      } else {
        this.pg.textAlign(p.CENTER, p.CENTER);

        this.pg.fill(255);
        this.pg.text(letters, cen.x + speedX / 10, cen.y + speedY / 10);

        // rgb
        if (this.glitch && rand >= this.rThresh - 0.2) {
          let rgbColor = rgbChannels[getRandomInt(0, 2)];
          this.pg.fill(rgbColor);
          let offset = this.offset + speedX / 2;
          this.pg.text(
            letters,
            cen.x + getRandomInt(-offset, offset + 4),
            cen.y
          );
        }
        // glitchy red
        if (rand >= this.rThresh) {
          let offset = this.offset * 100;
          this.pg.textSize(this.fontSize * getRandomInt(0.5, 1.5));
          this.pg.fill("rgba(255,0,0,0.5)");
          this.pg.text(
            letters,
            cen.x + getRandomInt(-offset, offset),
            cen.y + getRandomInt(-offset, offset)
          );
        }
        // large white
        if (rand < this.rThresh && rand > this.rThresh - 0.05) {
          let offset = getRandomInt(-this.offset * 4, this.offset * 4);
          let degrees = getRandomInt(0, 360);
          let rFontSize = getRandomInt(this.fontSize * 4, this.fontSize * 12);
          this.pg.rotate(degrees);
          this.pg.textSize(rFontSize);
          this.pg.fill(`rgba(255,255,255,${1 / (rFontSize / 200)})`);
          this.pg.text(
            letters,
            cen.x + getRandomInt(-offset, offset),
            cen.y + getRandomInt(-offset, offset)
          );
          this.pg.rotate(-degrees);
        }
      }

      // render tiles
      for (let x = 0; x < tiles; x++) {
        for (let y = 0; y < tiles; y++) {
          if (showTiles) {
            p.stroke(0, 255, 0);
            p.strokeWeight(1);
            p.noFill();
            p.rect(
              x * this.tileSize,
              y * this.tileSize,
              this.tileSize,
              this.tileSize
            );
          }

          const sx = (dx = x * this.tileSize);
          const sy = (dy = y * this.tileSize);
          const sw = this.tileSize;
          const sh = this.tileSize;

          var dis = p.dist(dx, dy, mX, mY);
          var pct = p.map(dis, 0, 200, 1, 0, true);

          let mouse = p.createVector(mX, mY);
          let v1 = p.createVector(dx, dy);
          let diff = p5.Vector.sub(v1, mouse);
          diff.normalize();

          v1 = v1.add(
            p5.Vector.mult(diff, (pct * p.width) / 6),
            p5.Vector.mult(diff, (pct * p.width) / 6)
          );

          p.image(this.pg, v1.x, v1.y, sw, sh, sx, sy, sw, sh);
        }
      }
    };
  }

  p.windowResized = () => {
    isPortrait = checkLayout();

    // if (isMobile()) return;

    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;
    p.resizeCanvas(windowWidth, windowHeight);
    glitchLetters.onResize(windowWidth, windowHeight);
  };

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }

  function checkLayout() {
    return p.height > p.width * 1.3;
  }

  function BigLetters(x, y, w, h, letters, loopDuration, font) {
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
    this.letters = letters.split("");
    this.letterIndex = 0;
    this.time = 0;
    this.finished = false;
    this.font = font;
    this.pg = p.createGraphics(w, h);

    this.pg.textFont(font);
    this.pg.textSize(w > h ? h : w);

    this.update = function () {
      let duration = loopDuration / (this.letters.length + 2);
      if (p.millis() - this.time >= duration) {
        if (this.letterIndex === this.letters.length - 1) {
          this.letterIndex = 0;
          this.finished = true;
          this.pg.remove();
        } else {
          this.letterIndex = this.letterIndex + 1;
        }

        this.time = p.millis();
      }
      this.draw();
    };

    this.draw = function () {
      this.pg.background(0);
      let cen = p.createVector(this.w / 2, this.h / 2 - this.h / 8);
      let offset = p.width / 400 + 3;

      this.pg.textAlign(p.CENTER, p.CENTER);
      this.pg.noStroke();
      this.pg.text(this.letters[this.letterIndex], cen.x, cen.y);

      if (p.frameCount % this.letters.length >= 3) {
        this.pg.fill(rgbChannels[getRandomInt(0, 2)]);
        this.pg.text(
          this.letters[this.letterIndex],
          cen.x + getRandomInt(-offset, offset),
          cen.y + getRandomInt(-offset, offset)
        );

        this.pg.blendMode(p.BLEND);
        this.pg.fill(0);
        this.pg.text(this.letters[this.letterIndex], cen.x, cen.y);
      }

      p.image(this.pg, this.x, this.y);
    };
  }

  function isMobile() {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }
};

const P5 = new p5(sketch);

export default P5;
