import gsap from "gsap";
import P5 from "./sketch";

(function () {
  const button = document.querySelector(".header__button");
  const content = document.querySelector(".content");
  const footer = document.querySelector(".footer");
  let announcement = document.querySelector(".announcement");

  const hasAnnouncement = announcement ? true : false;

  if (hasAnnouncement) {
    initAnnouncement(announcement);
    announcement = null;
  }

  button.addEventListener("click", () => {
    toggleContent();
  });

  function toggleContent() {
    if (!content.classList.contains("active")) {
      content.classList.add("active");
      button.classList.add("active");
      footer.classList.add("fill");

      let tl = gsap.timeline();
      tl.to(content, {
        autoAlpha: 1,
        duration: 0.3,
      })
        .to(
          ".about",
          {
            autoAlpha: 1,
            translateY: "-2px",
            duration: 0.3,
          },
          ">-0.1"
        )
        .to(
          ".clients",
          {
            autoAlpha: 1,
            duration: 0.3,
          },
          ">-0.1"
        )
        .to(
          ".clients ul li",
          {
            autoAlpha: 1,
            stagger: {
              amount: 1.5,
              ease: "circ.in",
            },
          },
          ">-0.1"
        )
        .to(
          ".mission",
          {
            autoAlpha: 1,
            translateY: "-2px",
            duration: 0.3,
          },
          ">-0.1"
        );
    } else {
      content.classList.remove("active");
      button.classList.remove("active");
      footer.classList.remove("fill");

      let tl = gsap.timeline();
      tl.to(content, {
        autoAlpha: 0,
        duration: 0.4,
      }).set([".about", ".clients", ".clients ul li", ".mission"], {
        autoAlpha: 0,
        translateY: 0,
      });
    }
  }

  function initEmail() {
    const how = "mailto:";
    const who = "people";
    const what = "morning";
    const eh = ".fyi";
    const yolo = "@";

    const where = document.getElementById("pingy");
    const thing = how + who + yolo + what + eh;

    where.setAttribute("href", thing);
    where.innerHTML = who + yolo + what + eh;
  }

  initEmail();
})();

function initAnnouncement(announcement) {
  gsap.to(".background__video", {
    autoAlpha: 0,
  });
  const modalOpenButtons = document.querySelectorAll("[data-open-modal]");
  const modalCloseButtons = document.querySelectorAll("[data-close-modal]");

  modalOpenButtons.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      openModal(button.getAttribute("data-open-modal"));
    });
  });

  modalCloseButtons.forEach((button) => {
    button.addEventListener("click", () => {
      closeModal(button.getAttribute("data-close-modal"));
    });
  });

  function openModal(modal_name) {
    let modal = document.querySelector(`[data-name=${modal_name}]`);
    gsap.set(modal, { autoAlpha: 1 });
  }

  function closeModal(modal_name) {
    let modal = document.querySelector(`[data-name=${modal_name}]`);
    gsap.to(modal, { autoAlpha: 0, duration: 0.2 });
  }

  function animateAnnouncement() {
    let tl = gsap.timeline();
    let tl2 = gsap.timeline({ yoyo: true, repeat: 10, repeatDelay: 2 });

    tl2
      .to(announcement, {
        rotate: -10,
        duration: 3,
        ease: "power2.in",
      })
      .to(announcement, {
        rotate: 350,
        duration: 0.4,
        ease: "power2.inOut",
      })
      .to(announcement, {
        rotate: 340,
        duration: 1,
        ease: "power2.inOut",
      });

    tl2.pause();

    tl.set(announcement, {
      autoAlpha: 1,
    })
      .from(announcement, {
        x: "500px",
        scale: 0.7,
        rotate: 120,
        ease: "expo.out",
        duration: 0.6,
        delay: 3,
        onComplete: addListeners,
      })
      .add(tl2);
    tl2.play();

    function addListeners() {
      announcement.addEventListener("mouseover", () => {
        tl.pause();
        gsap.to(announcement, {
          scale: 1.2,
        });
        gsap.to(".background__video", {
          autoAlpha: 1,
        });
        document.querySelector(".flame__video").play();
      });

      announcement.addEventListener("mouseout", () => {
        gsap.to(announcement, {
          scale: 1,
        });
        gsap.to(".background__video", {
          autoAlpha: 0,
        });
      });
    }
  }

  const emailForm = document.querySelector("#zine_form");

  if (emailForm) {
    // const checkbox = document.querySelector('#zine__tos')
    // checkbox.addEventListener('change', function(e) {
    //   if (checkbox.checked) submit.disabled = false
    //   else submit.disabled = true
    // })
    // const submit = document.querySelector('.zine_form__submit')

    emailForm.addEventListener("submit", (e) => {
      e.preventDefault();
      processForm(emailForm);
    });
  }

  function processForm(form) {
    const data = new FormData(form);
    data.append("form-name", "zine");

    fetch("/", {
      method: "POST",
      body: data,
    })
      .then((response) => {
        if (!response.ok) {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }
        return (form.innerHTML = `<div class="form--success">Thank you! Expect an email to your inbox very soon.</div>`);
      })
      .catch((error) => {
        console.log(error);
        form.innerHTML = `<div class="form--error">${error}</div>`;
      });
  }

  animateAnnouncement();
}
